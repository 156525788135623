export enum RegisterActionTypes {
  REGISTER_EMAIL_UPDATE_FIELD = 'REGISTER_EMAIL_UPDATE_FIELD',
  REGISTER_PASSWORD_UPDATE_FIELD = 'REGISTER_PASSWORD_UPDATE_FIELD',
  REGISTER_CODE_UPDATE_FIELD = 'REGISTER_CODE_UPDATE_FIELD',
  REGISTER_REPEAT_PASSWORD_UPDATE_FIELD = 'REGISTER_REPEAT_PASSWORD_UPDATE_FIELD',

  REGISTRATION_EMAIL_AND_PASSWORD = 'REGISTRATION_EMAIL_AND_PASSWORD',
  REGISTER_EMAIL_SUCSSES = 'REGISTER_EMAIL_SUCSSES',
  REGISTER_ERROR = 'REGISTER_EMAIL_ERROR',

  REGISTRATION_CODE = 'REGISTRATION_CODE',
  REGISTER_CODE_SUCSSES = 'REGISTER_CODE_SUCSSES',
  REGISTER_CODE_ERROR = 'REGISTER_CODE_ERROR',

  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',

  SET_INIT_REGISTER_STATE = 'SET_INIT_REGISTER_STATE',
}

/* REGISTER EMAIL EPIC */
export interface RegistrationEmailAndPassword {
  type: RegisterActionTypes.REGISTRATION_EMAIL_AND_PASSWORD;
  username: string;
  userPassword: string;
}
export interface RegisterSucsses {
  type: RegisterActionTypes.REGISTER_EMAIL_SUCSSES;
  user: any;
}

export interface RegisterError {
  type: RegisterActionTypes.REGISTER_ERROR;
  error: Error;
}

//========================================================

/* REGISTER CODE EPIC */
export interface RegistrationCode {
  type: RegisterActionTypes.REGISTRATION_CODE;
  username: string;
  code: string;
}
export interface RegisterCodeSucsses {
  type: RegisterActionTypes.REGISTER_CODE_SUCSSES;
  user: any;
}

interface RegisterCodeError {
  type: RegisterActionTypes.REGISTER_CODE_ERROR;
  error: Error;
}

//=========================================================

export interface OpenModal {
  type: RegisterActionTypes.OPEN_MODAL;
}

export interface CloseModal {
  type: RegisterActionTypes.CLOSE_MODAL;
}

//=========================================================

/*REGISTER REDUX */

export interface RegisterCodeUpdateField {
  type: RegisterActionTypes.REGISTER_CODE_UPDATE_FIELD;
  code: string;
}

export interface RegisterEmailUpdateField {
  type: RegisterActionTypes.REGISTER_EMAIL_UPDATE_FIELD;
  email: string;
}

interface RegisterPasswordUpdateField {
  type: RegisterActionTypes.REGISTER_PASSWORD_UPDATE_FIELD;
  password: string;
}

interface RegisterRepeatPasswordUpdateField {
  type: RegisterActionTypes.REGISTER_REPEAT_PASSWORD_UPDATE_FIELD;
  repeatPassword: string;
}

export interface SetInitRegisterState {
  type: RegisterActionTypes.SET_INIT_REGISTER_STATE;
}

//===========================================================

export function registrationEmailAndPassword(username: string, userPassword: string): RegistrationEmailAndPassword {
  return {
    type: RegisterActionTypes.REGISTRATION_EMAIL_AND_PASSWORD,
    username,
    userPassword,
  };
}
export function registerSucsses(user: any): RegisterSucsses {
  return {
    type: RegisterActionTypes.REGISTER_EMAIL_SUCSSES,
    user,
  };
}
export function registerError(error: Error): RegisterError {
  return {
    type: RegisterActionTypes.REGISTER_ERROR,
    error,
  };
}

//============================================================

export function registrationCode(username: string, code: string): RegistrationCode {
  return {
    type: RegisterActionTypes.REGISTRATION_CODE,
    username,
    code,
  };
}
export function registerCodeSucsses(user: any): RegisterCodeSucsses {
  return {
    type: RegisterActionTypes.REGISTER_CODE_SUCSSES,
    user,
  };
}

export function registerCodeError(error: Error): RegisterCodeError {
  return {
    type: RegisterActionTypes.REGISTER_CODE_ERROR,
    error,
  };
}

//=============================================================

export function openModalAction() {
  return {
    type: RegisterActionTypes.OPEN_MODAL,
  };
}

export function closeModalAction() {
  return {
    type: RegisterActionTypes.CLOSE_MODAL,
  };
}

//=============================================================

export function registerCodeUpdateField(code: string): RegisterCodeUpdateField {
  return {
    type: RegisterActionTypes.REGISTER_CODE_UPDATE_FIELD,
    code,
  };
}

export function registerEmailUpdateField(email: string): RegisterEmailUpdateField {
  return {
    type: RegisterActionTypes.REGISTER_EMAIL_UPDATE_FIELD,
    email,
  };
}

export function registerPasswordUpdateField(password: string): RegisterPasswordUpdateField {
  return {
    type: RegisterActionTypes.REGISTER_PASSWORD_UPDATE_FIELD,
    password,
  };
}

export function registerRepeatPasswordUpdateField(repeatPassword: string): RegisterRepeatPasswordUpdateField {
  return {
    type: RegisterActionTypes.REGISTER_REPEAT_PASSWORD_UPDATE_FIELD,
    repeatPassword,
  };
}

export function setInitRegisterState(): SetInitRegisterState {
  return {
    type: RegisterActionTypes.SET_INIT_REGISTER_STATE,
  };
}

export type RegisterActions =
  | RegisterEmailUpdateField
  | RegisterPasswordUpdateField
  | RegisterRepeatPasswordUpdateField
  | RegisterSucsses
  | RegisterError
  | RegisterCodeSucsses
  | RegisterCodeError
  | RegistrationEmailAndPassword
  | RegisterCodeUpdateField
  | OpenModal
  | CloseModal
  | RegistrationCode
  | SetInitRegisterState;

import { User } from '../../types';

export enum ListUserActionTypes {
  LIST_USER_REQUEST = 'LIST_USER_REQUEST',
  LIST_USER_SUCCESS = 'LIST_USER_SUCCESS',
  LIST_USER_ERROR = 'LIST_USER_ERROR',
}

export interface UserFilter {
  users: User[];
  total: number;
}

export interface ListUserRequest {
  type: ListUserActionTypes.LIST_USER_REQUEST;
  userFilter: UserFilter;
}

export interface ListUserSuccess {
  type: ListUserActionTypes.LIST_USER_SUCCESS;
  userFilter: UserFilter;
}

export interface ListUserError {
  type: ListUserActionTypes.LIST_USER_ERROR;
  error: Error;
}

export type ListUserActions = ListUserRequest | ListUserSuccess | ListUserError;

export function listUserRequest(userFilter: UserFilter): ListUserRequest {
  return {
    type: ListUserActionTypes.LIST_USER_REQUEST,
    userFilter,
  };
}
export function listUserSuccess(userFilter: UserFilter): ListUserSuccess {
  return {
    type: ListUserActionTypes.LIST_USER_SUCCESS,
    userFilter,
  };
}
export function listUserError(error: Error): ListUserError {
  return {
    type: ListUserActionTypes.LIST_USER_ERROR,
    error,
  };
}

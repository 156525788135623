export enum SingInActionType {
  SINGIN_EMAIL_UPDATE_FIELD = 'SINGIN_EMAIL_UPDATE_FIELD',
  SINGIN_PASSWORD_UPDATE_FIELD = 'SINGIN_PASSWORD_UPDATE_FIELD',
  SINGIN_EMAIL_AND_PASSWORD = 'SINGIN_EMAIL_AND_PASSWORD',
  SINGIN_EMAIL_SUCSSES = 'SINGIN_EMAIL_SUCSSES',
  SINGIN_EMAIL_ERROR = 'SINGIN_EMAIL_ERROR',

  SET_INITIAL_SING_IN_STATE = 'SET_INITIAL_SING_IN_STATE',
}

export interface SingInEmailAndPassword {
  type: SingInActionType.SINGIN_EMAIL_AND_PASSWORD;
  username: string;
  userPassword: string;
}

export interface SingInEmailSucsses {
  type: SingInActionType.SINGIN_EMAIL_SUCSSES;
  user: any;
}

interface SingInEmailError {
  type: SingInActionType.SINGIN_EMAIL_ERROR;
  error: Error;
}

interface SingInEmailUpdateField {
  type: SingInActionType.SINGIN_EMAIL_UPDATE_FIELD;
  email: string;
}

interface SingInPasswordUpdateField {
  type: SingInActionType.SINGIN_PASSWORD_UPDATE_FIELD;
  password: string;
}

export interface ResetTheState {
  type: SingInActionType.SET_INITIAL_SING_IN_STATE;
}

export type SingInAction =
  | SingInEmailUpdateField
  | SingInPasswordUpdateField
  | SingInEmailAndPassword
  | SingInEmailSucsses
  | SingInEmailError
  | ResetTheState;

export function singInEmailAndPassword(username: string, userPassword: string): SingInEmailAndPassword {
  return {
    type: SingInActionType.SINGIN_EMAIL_AND_PASSWORD,
    username,
    userPassword,
  };
}
export function singInEmailSucsses(user: any): SingInEmailSucsses {
  return {
    type: SingInActionType.SINGIN_EMAIL_SUCSSES,
    user,
  };
}

export function singInEmailError(error: Error): SingInEmailError {
  return {
    type: SingInActionType.SINGIN_EMAIL_ERROR,
    error,
  };
}

export function singInEmailUpdateField(email: string): SingInEmailUpdateField {
  return {
    type: SingInActionType.SINGIN_EMAIL_UPDATE_FIELD,
    email,
  };
}

export function singInPasswordUpdateField(password: string): SingInPasswordUpdateField {
  return {
    type: SingInActionType.SINGIN_PASSWORD_UPDATE_FIELD,
    password,
  };
}
export function setInitialSingInState(): ResetTheState {
  return {
    type: SingInActionType.SET_INITIAL_SING_IN_STATE,
  };
}

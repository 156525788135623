import update from 'immutability-helper';
import { UpdateDeviceActionType, UpdateDeviceActions } from './action';

export interface Location {
  lat: number;
  lon: number;
}

export interface UpdateDeviceState {
  loading: boolean;
  updateSuccess: boolean;
  updateDeviceError: null | Error;
  updateDeviceNotification: boolean;
  success: boolean;
}

export const initialUpdateDeviceState: UpdateDeviceState = {
  loading: false,
  updateSuccess: false,
  updateDeviceError: null,
  updateDeviceNotification: false,
  success: false,
};

export default function updateDeviceReducer(state = initialUpdateDeviceState, action: UpdateDeviceActions) {
  switch (action.type) {
    case UpdateDeviceActionType.UPDATE_DEVICE_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case UpdateDeviceActionType.UPDATE_DEVICE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        updateSuccess: { $set: true },
      });

    case UpdateDeviceActionType.UPDATE_DEVICE_ERROR:
      return update(state, {
        loading: { $set: false },
        updateDeviceError: { $set: action.error },
      });

    case UpdateDeviceActionType.OPEN_UDATE_DEVICE_NOTIFICATION:
      return update(state, {
        updateDeviceNotification: { $set: true },
      });

    case UpdateDeviceActionType.CLOSE_UPDATE_DEVICE_NOTIFICATION:
      return update(state, {
        updateDeviceNotification: { $set: false },
      });
    case UpdateDeviceActionType.GET_DEFAULT_STATE:
      return initialUpdateDeviceState;

    default:
      return state;
  }
}

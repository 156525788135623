import { gql } from '@apollo/client';

export const getDeviceQuery = gql`
  query getDevice($input: GetDeviceInput!) {
    getDevice(input: $input) {
      ... on Terminal {
        id
        typename: __typename
        address
        approvalsCount
        location {
          lat
          lon
        }
        notes
        registrationDate
        organization
        status
        user {
          id
        }
      }
    }
  }
`;

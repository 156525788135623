import { AddComponentsAcion, AddComponentsAcionType } from './action';
import update from 'immutability-helper';

export interface AddComponentsState {
  createDeviceWindow: boolean;
  getDeviceWindow: boolean;
  modalMap: boolean;
  updateDeviceWndow: boolean;
}

export const initialAddComponentState: AddComponentsState = {
  createDeviceWindow: false,
  getDeviceWindow: false,
  modalMap: false,
  updateDeviceWndow: false,
};

const addComponentsReducer = (state = initialAddComponentState, action: AddComponentsAcion) => {
  switch (action.type) {
    case AddComponentsAcionType.SET_OPEN_CREATE_DEVICE_WINDOW:
      return update(state, {
        createDeviceWindow: { $set: true },
      });
    case AddComponentsAcionType.SET_CLOSE_CREATE_DEVICE_WINDOW:
      return update(state, {
        createDeviceWindow: { $set: false },
      });
    case AddComponentsAcionType.SET_OPEN_GET_DEVICE_WINDOW:
      return update(state, {
        getDeviceWindow: { $set: true },
      });
    case AddComponentsAcionType.SET_CLOSE_GET_DEVICE_WINDOW:
      return update(state, {
        getDeviceWindow: { $set: false },
      });
    case AddComponentsAcionType.SET_OPEN_MODAL_MAP:
      return update(state, {
        modalMap: { $set: true },
      });
    case AddComponentsAcionType.SET_CLOSE_MODAL_MAP:
      return update(state, {
        modalMap: { $set: false },
      });
    case AddComponentsAcionType.SET_OPEN_UPDATE_DEVICE_WINDOW:
      return update(state, {
        updateDeviceWndow: { $set: true },
      });
    case AddComponentsAcionType.SET_CLOSE_UPDATE_DEVICE_WINDOW:
      return update(state, {
        updateDeviceWndow: { $set: false },
      });
    default:
      return state;
  }
};

export default addComponentsReducer;

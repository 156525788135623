import { GetDevicesActions, GetDiviceActionTypes } from './action';
import update from 'immutability-helper';

enum DeviceStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

export interface GetDevicesState {
  loading: boolean;
  error: null | Error;
  getDeviceData: {
    address: string;
    approvalsCount: number;
    id: string;
    location: {
      lat: number;
      lon: number;
    };
    notes: string;
    registrationDate: string;
    organization: string;
    status: DeviceStatus;
    user: any;
  };
  getDeviceSuccess: boolean;
}

export const initialGetDevicesState: GetDevicesState = {
  loading: false,
  error: null,
  getDeviceData: null,
  getDeviceSuccess: false,
};

export default function getDevicesReducer(state = initialGetDevicesState, action: GetDevicesActions) {
  switch (action.type) {
    case GetDiviceActionTypes.GET_DEVICES_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case GetDiviceActionTypes.GET_DEVICES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        getDeviceData: { $set: action.getDeviceData },
        getDeviceSuccess: { $set: true },
      });

    case GetDiviceActionTypes.GET_DEVICES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case GetDiviceActionTypes.GET_DEVICES_RESET:
      return initialGetDevicesState;

    default:
      return state;
  }
}

import { Observable } from 'redux';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { deleteDeviceMutation } from '../query';
import { filter, switchMap } from 'rxjs';
import {
  DeleteDeviceActionType,
  DeleteDeviceActions,
  DeleteDeviceRequestAction,
  deleteDeviceError,
  deleteDeviceSuccess,
} from './action';

export function deleteDeviceEpic(action$): Observable<DeleteDeviceActions> {
  return action$.pipe(
    filter((action: DeleteDeviceActions) => action.type === DeleteDeviceActionType.DELETE_DEVICE_REQUEST),
    switchMap((action: DeleteDeviceRequestAction) => {
      return deleteDevice(action.id)
        .then(deleteDeviceSuccess)
        .catch((error) => {
          return deleteDeviceError(error);
        });
    }),
  );
}

export async function deleteDevice(id) {
  try {
    const graphQLClient = await setAuthenticatedClient();

    const response = await graphQLClient.mutate({
      mutation: deleteDeviceMutation,
      variables: { input: { id } },
    });

    const device = response.data.deleteDevice;
    return device;
  } catch (error) {
    throw error;
  }
}

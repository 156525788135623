export enum AddComponentsAcionType {
  SET_OPEN_CREATE_DEVICE_WINDOW = 'SET_OPEN_CREATE_DEVICE_WINDOW',
  SET_CLOSE_CREATE_DEVICE_WINDOW = 'SET_CLOSE_CREATE_DEVICE_WINDOW',

  SET_OPEN_GET_DEVICE_WINDOW = 'SET_OPEN_GET_DEVICE_WINDOW',
  SET_CLOSE_GET_DEVICE_WINDOW = 'SET_CLOSE_GET_DEVICE_WINDOW',

  SET_OPEN_MODAL_MAP = 'SET_OPEN_MODAL_MAP',
  SET_CLOSE_MODAL_MAP = 'SET_CLOSE_MODAL_MAP',

  SET_OPEN_UPDATE_DEVICE_WINDOW = 'SET_OPEN_UPDATE_DEVICE_WINDOW',
  SET_CLOSE_UPDATE_DEVICE_WINDOW = 'SET_CLOSE_UPDATE_DEVICE_WINDOW',
}

export interface OpenUpdateDeviceWindow {
  type: AddComponentsAcionType.SET_OPEN_UPDATE_DEVICE_WINDOW;
}

export interface CloseUpdateDeviceWindow {
  type: AddComponentsAcionType.SET_CLOSE_UPDATE_DEVICE_WINDOW;
}

export interface OpenCreateDeviceWindow {
  type: AddComponentsAcionType.SET_OPEN_CREATE_DEVICE_WINDOW;
}
export interface CloseCreateDeviceWindow {
  type: AddComponentsAcionType.SET_CLOSE_CREATE_DEVICE_WINDOW;
}

export interface OpenGetDeviceWindow {
  type: AddComponentsAcionType.SET_OPEN_GET_DEVICE_WINDOW;
}
export interface CloseGetDeviceWindow {
  type: AddComponentsAcionType.SET_CLOSE_GET_DEVICE_WINDOW;
}

export interface OpenModalMap {
  type: AddComponentsAcionType.SET_OPEN_MODAL_MAP;
}
export interface CloseModalMap {
  type: AddComponentsAcionType.SET_CLOSE_MODAL_MAP;
}

export function setOpenCreateDeviceWindow(): OpenCreateDeviceWindow {
  return {
    type: AddComponentsAcionType.SET_OPEN_CREATE_DEVICE_WINDOW,
  };
}
export function setCloseCreateDeviceWindow(): CloseCreateDeviceWindow {
  return {
    type: AddComponentsAcionType.SET_CLOSE_CREATE_DEVICE_WINDOW,
  };
}

export function setOpenGetDeviceWindow(): OpenGetDeviceWindow {
  return {
    type: AddComponentsAcionType.SET_OPEN_GET_DEVICE_WINDOW,
  };
}
export function setCloseGetDeviceWindow(): CloseGetDeviceWindow {
  return {
    type: AddComponentsAcionType.SET_CLOSE_GET_DEVICE_WINDOW,
  };
}

export function setOpenModalMap(): OpenModalMap {
  return {
    type: AddComponentsAcionType.SET_OPEN_MODAL_MAP,
  };
}
export function setCloseModalMap(): CloseModalMap {
  return {
    type: AddComponentsAcionType.SET_CLOSE_MODAL_MAP,
  };
}

export function setOpenUpdateDeviceWindow(): OpenUpdateDeviceWindow {
  return {
    type: AddComponentsAcionType.SET_OPEN_UPDATE_DEVICE_WINDOW,
  };
}
export function setCloseUpdateDeviceWindow(): CloseUpdateDeviceWindow {
  return {
    type: AddComponentsAcionType.SET_CLOSE_UPDATE_DEVICE_WINDOW,
  };
}

export type AddComponentsAcion =
  | OpenCreateDeviceWindow
  | CloseCreateDeviceWindow
  | OpenGetDeviceWindow
  | CloseGetDeviceWindow
  | OpenModalMap
  | CloseModalMap
  | OpenUpdateDeviceWindow
  | CloseUpdateDeviceWindow;

import { WebHeroActionTypes, WebHeroActions } from './action';
import Page from '../../pages';

export interface WebHeroState {
  currentPage: Page;
}

const initialState: WebHeroState = {
  currentPage: Page.InitialStage,
};

const webHeroReducer = (state = initialState, action: WebHeroActions): WebHeroState => {
  switch (action.type) {
    case WebHeroActionTypes.SET_CURRENT_PAGE:
      return {
        currentPage: action.stage,
      };

    default:
      return state;
  }
};

export default webHeroReducer;

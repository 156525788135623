import { Device } from '../../types';

interface DeviceFilter {
  devices: Device[];
  total: number;
}

export enum ListDevicesActionTypes {
  LIST_DEVICE_REQUEST = 'LIST_DEVICE_REQUEST',
  LIST_DEVICE_SUCCESS = 'LIST_DEVICE_SUCCESS',
  LIST_DEVICE_ERROR = 'LIST_DEVICE_ERROR',
}

export interface ListDevicesRequestAction {
  type: ListDevicesActionTypes.LIST_DEVICE_REQUEST;
  filter: DeviceFilter;
}
export interface ListDevicesSuccessAction {
  type: ListDevicesActionTypes.LIST_DEVICE_SUCCESS;
  filter: DeviceFilter;
}
export interface ListDevicesErrorAction {
  type: ListDevicesActionTypes.LIST_DEVICE_ERROR;
  error: Error;
}

export type ListDevicesAction = ListDevicesRequestAction | ListDevicesSuccessAction | ListDevicesErrorAction;

export function listDevicesRequest(filter: DeviceFilter): ListDevicesRequestAction {
  return {
    type: ListDevicesActionTypes.LIST_DEVICE_REQUEST,
    filter,
  };
}
export function listDevicesSuccess(filter: DeviceFilter): ListDevicesSuccessAction {
  return {
    type: ListDevicesActionTypes.LIST_DEVICE_SUCCESS,
    filter,
  };
}
export function listDevicesError(error: Error): ListDevicesErrorAction {
  return {
    type: ListDevicesActionTypes.LIST_DEVICE_ERROR,
    error,
  };
}

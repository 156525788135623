import update from 'immutability-helper';
import { UpdateUserActionTypes, UpdateUserActions } from './action';

export enum UserType {
  USER = 'USER',
}

export interface UpdateUserState {
  updateUserLoading: boolean;
  updateUserError: Error | null;
  updateUserSuccessFields: {
    id: string;
    username: string;
    userType: UserType;
    phoneNumber: string;
    avatar: string;
  };
  updateUserNotification: boolean;
}

export const initialUpdateUserState: UpdateUserState = {
  updateUserLoading: false,
  updateUserError: null,
  updateUserSuccessFields: null,
  updateUserNotification: false,
};

export default function updateUserReducer(state = initialUpdateUserState, action: UpdateUserActions) {
  switch (action.type) {
    case UpdateUserActionTypes.UPDATE_USER_REQUEST:
      return update(state, {
        updateUserLoading: { $set: true },
      });
    case UpdateUserActionTypes.UPDATE_USER_SUCCESS:
      return update(state, {
        updateUserLoading: { $set: false },
        updateUserSuccessFields: { $set: action.updateUserSuccessFields },
      });
    case UpdateUserActionTypes.UPDATE_USER_ERROR:
      return update(state, {
        updateUserLoading: { $set: false },
        updateUserError: { $set: action.updateUserError },
      });
    case UpdateUserActionTypes.OPEN_UPDATE_USER_NOTIFICATION:
      return update(state, {
        updateUserNotification: { $set: true },
      });
    case UpdateUserActionTypes.CLOSE_UPDATE_USER_NOTIFICATION:
      return update(state, {
        updateUserNotification: { $set: false },
      });
    default:
      return state;
  }
}

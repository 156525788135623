export enum GetUserActionType {
  GET_USER_REQUEST = 'GET_USER_REQEST',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_ERROR = 'GET_USER_ERROR',
}

export interface GetUserRequest {
  type: GetUserActionType.GET_USER_REQUEST;
  id: string;
}

export interface GetUserSuccess {
  type: GetUserActionType.GET_USER_SUCCESS;
  getUserData: any;
}

export interface GetUserError {
  type: GetUserActionType.GET_USER_ERROR;
  error: Error;
}

export type GetuserActions = GetUserRequest | GetUserSuccess | GetUserError;

export function getUserRequest(id: string): GetUserRequest {
  return {
    type: GetUserActionType.GET_USER_REQUEST,
    id,
  };
}

export function getUserSuccess(getUserData: any): GetUserSuccess {
  return {
    type: GetUserActionType.GET_USER_SUCCESS,
    getUserData,
  };
}

export function getUserError(error: Error): GetUserError {
  return {
    type: GetUserActionType.GET_USER_ERROR,
    error,
  };
}

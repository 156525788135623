import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { getSession } from '../../../client/graphql';
import { InitialAction, InitialClientActionsType, initialClientError, initialClientSuccess } from './action';

export function initialClientEpic(action$): Observable<InitialAction> {
  return action$.pipe(
    filter((action: InitialAction) => action.type === InitialClientActionsType.INIT_CLIENT_REQUEST),
    switchMap(({}) => getSession().then(initialClientSuccess).catch(initialClientError)),
  );
}

import update from 'immutability-helper';
import { SingInAction, SingInActionType } from './action';

export interface SingInState {
  singInEmail: string;
  singInPassword?: string;
  repeatPassword?: string;
  code?: number | string;
  loading?: boolean;
  error?: Error | null;
  emailError?: Error | null;
  passwordError?: Error | null;
  repeatPasswordError?: Error | null;
  username: string;
  userPassword: string;

  singInEmailError: Error | null;
  singInEmailSucsses: boolean;
}

export const initialSingInState: SingInState = {
  singInEmail: '',
  singInPassword: '',
  repeatPassword: '',
  loading: false,
  error: null,
  emailError: null,
  passwordError: null,
  repeatPasswordError: null,

  singInEmailError: null,
  singInEmailSucsses: false,
  username: '',
  userPassword: '',
};

const singinReducer = (state = initialSingInState, action: SingInAction) => {
  switch (action.type) {
    case SingInActionType.SINGIN_EMAIL_AND_PASSWORD:
      return update(state, {
        username: { $set: action.username },
        userPassword: { $set: action.userPassword },
      });
    case SingInActionType.SINGIN_EMAIL_SUCSSES:
      return update(state, {
        singInEmailSucsses: { $set: true },
      });
    case SingInActionType.SINGIN_EMAIL_ERROR:
      return update(state, {
        singInEmailError: { $set: action.error },
      });
    case SingInActionType.SINGIN_EMAIL_UPDATE_FIELD:
      return update(state, {
        singInEmail: { $set: action.email },
      });
    case SingInActionType.SINGIN_PASSWORD_UPDATE_FIELD:
      return update(state, {
        singInPassword: { $set: action.password },
      });
    case SingInActionType.SET_INITIAL_SING_IN_STATE:
      return update(state, {
        $merge: {
          ...initialSingInState,
          username: state.username,
          userPassword: state.userPassword,
          singInEmail: state.singInEmail,
          singInPassword: state.singInPassword,
        },
      });
    default:
      return state;
  }
};

export default singinReducer;

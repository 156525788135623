import { Observable, filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { listDevicesQuery } from '../query';
import {
  ListDevicesAction,
  ListDevicesActionTypes,
  ListDevicesRequestAction,
  listDevicesSuccess,
  listDevicesError,
} from './action';

export function listDeviceEpic(action$): Observable<ListDevicesAction> {
  return action$.pipe(
    filter((action: ListDevicesAction) => action.type === ListDevicesActionTypes.LIST_DEVICE_REQUEST),
    switchMap(({ filter }: ListDevicesRequestAction) =>
      listDevices(filter).then(listDevicesSuccess).catch(listDevicesError),
    ),
  );
}

export async function listDevices({ filter }: any): Promise<any> {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: {
      listDevices: { devices, total },
    },
  } = await graphQLClient.query({
    query: listDevicesQuery,
    variables: {
      input: {
        filter,
      },
    },
  });

  return { total, devices };
}

import { Observable, filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { createDeviceMutation } from '../query';
import {
  CreateDeviceAction,
  CreateDeviceActionTypes,
  CreateDeviceRequestAction,
  createDeviceError,
  createDeviceSuccess,
} from './action';

export function createDeviceEpic(action$): Observable<CreateDeviceAction> {
  return action$.pipe(
    filter((action: CreateDeviceAction) => action.type === CreateDeviceActionTypes.CREATE_DEVICES_REQUEST),
    switchMap(({ input }: CreateDeviceRequestAction) =>
      createDevice(input).then(createDeviceSuccess).catch(createDeviceError),
    ),
  );
}

export async function createDevice(input) {
  const graphQLClient = await setAuthenticatedClient();
  const {
    data: { createDevice: device },
  } = await graphQLClient.mutate({
    mutation: createDeviceMutation,
    variables: { input },
  });

  return device;
}

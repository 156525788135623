import CognitoClient from '@mancho.devs/cognito';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import {
  RegisterActionTypes,
  RegisterActions,
  registerSucsses,
  registerError,
  registerCodeSucsses,
  registerCodeError,
  RegistrationEmailAndPassword,
  RegistrationCode,
} from './actions';

import { isJobStale } from 'gatsby/dist/utils/jobs/manager';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function registerEpic(action$): Observable<RegisterActions> {
  return action$.pipe(
    filter((action: RegisterActions) => action.type === RegisterActionTypes.REGISTRATION_EMAIL_AND_PASSWORD),
    switchMap(({ username, userPassword }: RegistrationEmailAndPassword) =>
      cognitoClient.signUp(username, userPassword).then(registerSucsses).catch(registerError),
    ),
  );
}

export function smsCodeEpic(action$): Observable<RegisterActions> {
  return action$.pipe(
    filter((action: RegisterActions) => action.type === RegisterActionTypes.REGISTRATION_CODE),
    switchMap(({ username, code }: RegistrationCode) =>
      cognitoClient.signUpConfirmCode(username, code).then(registerCodeSucsses).catch(registerCodeError),
    ),
  );
}

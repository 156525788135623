export enum GetDiviceActionTypes {
  GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST',
  GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS',
  GET_DEVICES_RESET = 'GET_DEVICES_RESET',
  GET_DEVICES_ERROR = 'GET_DEVICES_ERROR',
  
}

export interface GetDevicesRequestAction {
  type: GetDiviceActionTypes.GET_DEVICES_REQUEST;
  id: string;
}
export interface GetDevicesResetAction {
  type: GetDiviceActionTypes.GET_DEVICES_RESET;
}

export interface GetDevicesSuccessAction {
  type: GetDiviceActionTypes.GET_DEVICES_SUCCESS;
  getDeviceData: any;
}

export interface GetDevicesErrorAction {
  type: GetDiviceActionTypes.GET_DEVICES_ERROR;
  error: Error;
}

export type GetDevicesActions =
  | GetDevicesRequestAction
  | GetDevicesSuccessAction
  | GetDevicesErrorAction
  | GetDevicesResetAction;

export function getDevicesRequest(id: string): GetDevicesRequestAction {
  return {
    type: GetDiviceActionTypes.GET_DEVICES_REQUEST,
    id,
  };
}

export function getDevicesSuccess(getDeviceData: any): GetDevicesSuccessAction {
  return {
    type: GetDiviceActionTypes.GET_DEVICES_SUCCESS,
    getDeviceData,
  };
}

export function getDevicesError(error: Error): GetDevicesErrorAction {
  return {
    type: GetDiviceActionTypes.GET_DEVICES_ERROR,
    error,
  };
}

export function getDevicesReset(): GetDevicesResetAction {
  return {
    type: GetDiviceActionTypes.GET_DEVICES_RESET,
  };
}

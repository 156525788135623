export enum UpdateDeviceActionType {
  UPDATE_DEVICE_REQUEST = 'UPDATE_DEVICE_REQUEST',
  UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS',
  UPDATE_DEVICE_ERROR = 'UPDATE_DEVICE_ERROR',

  OPEN_UDATE_DEVICE_NOTIFICATION = 'OPEN_UDATE_DEVICE_NOTIFICATION',
  CLOSE_UPDATE_DEVICE_NOTIFICATION = 'CLOSE_UPDATE_DEVICE_NOTIFICATION',

  GET_DEFAULT_STATE = 'GET_DEFAULT_STATE',
}

export interface UpdateDeviceRequest {
  type: UpdateDeviceActionType.UPDATE_DEVICE_REQUEST;
  updatedDeviceFields: any;
}

export interface UpdateDeviceSuccess {
  type: UpdateDeviceActionType.UPDATE_DEVICE_SUCCESS;
  updatedDeviceSuccessFields: any;
}

export interface UpdateDeviceError {
  type: UpdateDeviceActionType.UPDATE_DEVICE_ERROR;
  error: Error;
}

export interface OpenUpdateDeviceNotification {
  type: UpdateDeviceActionType.OPEN_UDATE_DEVICE_NOTIFICATION;
}
export interface CloseUpdateDeviceNotification {
  type: UpdateDeviceActionType.CLOSE_UPDATE_DEVICE_NOTIFICATION;
}

export interface GetDefaultState {
  type: UpdateDeviceActionType.GET_DEFAULT_STATE;
}

export type UpdateDeviceActions =
  | UpdateDeviceRequest
  | UpdateDeviceSuccess
  | UpdateDeviceError
  | OpenUpdateDeviceNotification
  | CloseUpdateDeviceNotification
  | GetDefaultState;

export function updateDeviceRequest(updatedDeviceFields: any): UpdateDeviceRequest {
  return {
    type: UpdateDeviceActionType.UPDATE_DEVICE_REQUEST,
    updatedDeviceFields,
  };
}

export function updateDeviceSuccess(updatedDeviceSuccessFields: any): UpdateDeviceSuccess {
  return {
    type: UpdateDeviceActionType.UPDATE_DEVICE_SUCCESS,
    updatedDeviceSuccessFields,
  };
}

export function updateDeviceError(error: Error): UpdateDeviceError {
  return {
    type: UpdateDeviceActionType.UPDATE_DEVICE_ERROR,
    error,
  };
}

export function openUpdateDeviceNotification(): OpenUpdateDeviceNotification {
  return {
    type: UpdateDeviceActionType.OPEN_UDATE_DEVICE_NOTIFICATION,
  };
}
export function closeUpdateDeviceNotification(): CloseUpdateDeviceNotification {
  return {
    type: UpdateDeviceActionType.CLOSE_UPDATE_DEVICE_NOTIFICATION,
  };
}
export function getDefaultState(): GetDefaultState {
  return {
    type: UpdateDeviceActionType.GET_DEFAULT_STATE,
  };
}

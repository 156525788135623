export enum DeleteDeviceActionType {
  DELETE_DEVICE_REQUEST = 'DELETE_DEVICE_REQUEST',
  DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_ERROR = 'DELETE_DEVICE_ERROR',

  OPEN_DELETE_DEVICE_NOTIFICATION = 'OPEN_DELETE_DEVICE_NOTIFICATION',
  CLOSE_DELETE_DEVICE_NOTIFICATION = 'CLOSE_DELETE_DEVICE_NOTIFICATION',
}

export interface DeleteDeviceRequestAction {
  type: DeleteDeviceActionType.DELETE_DEVICE_REQUEST;
  id: string;
}

export interface DeleteDeviceSuccessAction {
  type: DeleteDeviceActionType.DELETE_DEVICE_SUCCESS;
  deleteDevice: any;
}

export interface DeleteDeviceErrorAction {
  type: DeleteDeviceActionType.DELETE_DEVICE_ERROR;
  deleteError: Error;
}

export interface OpenDeleteDeviceNotification {
  type: DeleteDeviceActionType.OPEN_DELETE_DEVICE_NOTIFICATION;
}
export interface CloseDeleteDeviceNotification {
  type: DeleteDeviceActionType.CLOSE_DELETE_DEVICE_NOTIFICATION;
}

export type DeleteDeviceActions =
  | DeleteDeviceRequestAction
  | DeleteDeviceSuccessAction
  | DeleteDeviceErrorAction
  | OpenDeleteDeviceNotification
  | CloseDeleteDeviceNotification;

export function deleteDeviceRequest(id: string): DeleteDeviceRequestAction {
  return {
    type: DeleteDeviceActionType.DELETE_DEVICE_REQUEST,
    id,
  };
}

export function deleteDeviceSuccess(deleteDevice: any): DeleteDeviceSuccessAction {
  return {
    type: DeleteDeviceActionType.DELETE_DEVICE_SUCCESS,
    deleteDevice,
  };
}

export function deleteDeviceError(deleteError: Error): DeleteDeviceErrorAction {
  return {
    type: DeleteDeviceActionType.DELETE_DEVICE_ERROR,
    deleteError,
  };
}

export function openDeleteDeviceNotification(): OpenDeleteDeviceNotification {
  return {
    type: DeleteDeviceActionType.OPEN_DELETE_DEVICE_NOTIFICATION,
  };
}
export function closeDeleteDeviceNotification(): CloseDeleteDeviceNotification {
  return {
    type: DeleteDeviceActionType.CLOSE_DELETE_DEVICE_NOTIFICATION,
  };
}

import CognitoClient from '@mancho.devs/cognito';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { SingInActionType, SingInAction, SingInEmailAndPassword, singInEmailSucsses, singInEmailError } from './action';

import { isJobStale } from 'gatsby/dist/utils/jobs/manager';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function singInEmailEpic(action$): Observable<SingInAction> {
  return action$.pipe(
    filter((action: SingInAction) => action.type === SingInActionType.SINGIN_EMAIL_AND_PASSWORD),
    switchMap(({ username, userPassword }: SingInEmailAndPassword) =>
      cognitoClient.signIn(username, userPassword).then(singInEmailSucsses).catch(singInEmailError),
    ),
  );
}

// External dependencies
import { gql } from '@apollo/client';

export const createDeviceMutation = gql`
  mutation CreateDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      ... on Terminal {
        address
        approvalsCount
        id
        location {
          lat
          lon
        }
        notes
        registrationDate
        organization
        status
        user {
          id
        }
        typename: __typename
      }
    }
  }
`;

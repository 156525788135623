export enum ApproveDeviceTypes {
  APPROVE_DEVICE_REQUEST = 'APPROVE_DEVICE_REQUEST',
  APPROVE_DEVICE_SUCCESS = 'APPROVE_DEVICE_SUCCESS',
  APPROVE_DEVICE_ERROR = 'APPROVE_DEVICE_ERROR',

  OPEN_APPROVE_NOTIFICATION = 'OPEN_APPROVE_NOTIFICATION',
  CLOSE_APPROVE_NOTIFICATION = 'CLOSE_APPROVE_NOTIFICATION',

  SET_RESET_STATE = 'SET_RESET_STATE',
}

export interface ApproveDeviceRequest {
  type: ApproveDeviceTypes.APPROVE_DEVICE_REQUEST;
  id: string;
  lat: number;
  lon: number;
}

export interface ApproveDeviceSuccess {
  type: ApproveDeviceTypes.APPROVE_DEVICE_SUCCESS;
  payload: any;
}

export interface ApproveDeviceError {
  type: ApproveDeviceTypes.APPROVE_DEVICE_ERROR;
  approveError: Error;
}

export interface OpenApproveNotification {
  type: ApproveDeviceTypes.OPEN_APPROVE_NOTIFICATION;
}
export interface CloseApproveNotification {
  type: ApproveDeviceTypes.CLOSE_APPROVE_NOTIFICATION;
}

export interface SetResetState {
  type: ApproveDeviceTypes.SET_RESET_STATE;
}

export type ApproveDeviceActions =
  | ApproveDeviceRequest
  | ApproveDeviceSuccess
  | ApproveDeviceError
  | OpenApproveNotification
  | CloseApproveNotification
  | SetResetState;

export function approveDeviceRequest(id: string, lat: number, lon: number): ApproveDeviceRequest {
  return {
    type: ApproveDeviceTypes.APPROVE_DEVICE_REQUEST,
    id,
    lat,
    lon,
  };
}

export function approveDeviceSuccess(payload: any): ApproveDeviceSuccess {
  return {
    type: ApproveDeviceTypes.APPROVE_DEVICE_SUCCESS,
    payload,
  };
}

export function approveDeviceError(approveError: Error): ApproveDeviceError {
  return {
    type: ApproveDeviceTypes.APPROVE_DEVICE_ERROR,
    approveError,
  };
}

export function openApproveNotification(): OpenApproveNotification {
  return {
    type: ApproveDeviceTypes.OPEN_APPROVE_NOTIFICATION,
  };
}

export function closeApproveNotification(): CloseApproveNotification {
  return {
    type: ApproveDeviceTypes.CLOSE_APPROVE_NOTIFICATION,
  };
}

export function setResetState(): SetResetState {
  return {
    type: ApproveDeviceTypes.SET_RESET_STATE,
  };
}

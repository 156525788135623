import update from 'immutability-helper';
import { RegisterActions, RegisterActionTypes } from './actions';

export interface RegisterState {
  email?: string;
  password?: string;
  repeatPassword?: string;
  code?: string;
  loading?: boolean;
  error?: Error;
  emailError?: Error;
  passwordError?: Error;
  repeatPasswordError?: Error;
  username?: string;
  userPassword?: string;
  isModalOpen: boolean;

  userEmail: string | null;

  registerEmailSucsses: boolean;
  registerError: Error | null;

  registerCodeSucsses: boolean;
  registerCodeError: Error | null;
}

export const initialRegisterState: RegisterState = {
  loading: false,
  error: null,
  email: '',
  password: '',
  isModalOpen: false,
  registerError: null,
  registerCodeError: null,
  registerCodeSucsses: false,
  registerEmailSucsses: false,
  userEmail: null,
};

const registerReducer = (state = initialRegisterState, action: RegisterActions) => {
  switch (action.type) {
    case RegisterActionTypes.REGISTRATION_EMAIL_AND_PASSWORD:
      return update(state, {
        username: { $set: action.username },
        userPassword: { $set: action.userPassword },
      });
    case RegisterActionTypes.REGISTER_EMAIL_SUCSSES:
      return update(state, {
        registerEmailSucsses: { $set: true },
        userEmail: { $set: state.email },
      });
    case RegisterActionTypes.REGISTER_ERROR:
      return update(state, {
        registerError: { $set: action.error },
      });

    case RegisterActionTypes.REGISTRATION_CODE:
      return update(state, {
        username: { $set: action.username },
        code: { $set: action.code },
      });
    case RegisterActionTypes.REGISTER_CODE_SUCSSES:
      return update(state, {
        registerCodeSucsses: { $set: true },
      });
    case RegisterActionTypes.REGISTER_CODE_ERROR:
      return update(state, {
        registerCodeError: { $set: action.error },
      });

    case RegisterActionTypes.OPEN_MODAL:
      return update(state, {
        isModalOpen: { $set: true },
      });
    case RegisterActionTypes.CLOSE_MODAL:
      return update(state, {
        isModalOpen: { $set: false },
      });

    case RegisterActionTypes.REGISTER_EMAIL_UPDATE_FIELD:
      return update(state, {
        email: { $set: action.email },
      });
    case RegisterActionTypes.REGISTER_CODE_UPDATE_FIELD:
      return update(state, {
        code: { $set: action.code },
      });
    case RegisterActionTypes.REGISTER_PASSWORD_UPDATE_FIELD:
      return update(state, {
        password: { $set: action.password },
      });
    case RegisterActionTypes.REGISTER_REPEAT_PASSWORD_UPDATE_FIELD:
      return update(state, {
        repeatPassword: { $set: action.repeatPassword },
      });
    case RegisterActionTypes.SET_INIT_REGISTER_STATE:
      return initialRegisterState;
    default:
      return state;
  }
};

export default registerReducer;

import update from 'immutability-helper';
import { MapLocationActionTypes, MapLocationActions, Cordinats } from './action';

export interface MapLocationState {
  getAddressLoading: boolean;
  cordinates: Cordinats | null;
  mapAddress: string;
  useLoc: {
    lat: number;
    lon: number;
  };
  isActiveMarker: boolean;
  isActiveMarkerId: string;
}

export const initState: MapLocationState = {
  cordinates: null,
  mapAddress: '',
  useLoc: null,
  isActiveMarker: false,
  isActiveMarkerId: null,
  getAddressLoading: false,
};

const mapLocationReducer = (state = initState, action: MapLocationActions): MapLocationState => {
  switch (action.type) {
    case MapLocationActionTypes.SET_CORDINATS:
      return update(state, {
        cordinates: {
          $set: {
            lat: action.lat,
            lon: action.lon,
            type: MapLocationActionTypes.SET_CORDINATS,
          },
        },
      });
    case MapLocationActionTypes.SET_ADDRESS:
      return update(state, {
        getAddressLoading: { $set: true },
        mapAddress: {
          $set: action.mapAddress,
        },
      });
    case MapLocationActionTypes.SET_USER_LOCATION:
      return update(state, {
        useLoc: {
          $set: {
            lat: action.lat,
            lon: action.lon,
          },
        },
      });
    case MapLocationActionTypes.SET_ACTIVE_MARKER:
      return update(state, {
        isActiveMarker: { $set: action.activeMarker },
        isActiveMarkerId: { $set: action.activeId },
      });
    case MapLocationActionTypes.SET_ADDRESS_RESET:
      return initState;
    default:
      return state;
  }
};

export default mapLocationReducer;

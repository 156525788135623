// External dependencies
import { gql } from '@apollo/client';

export const deleteUserMutation = gql`
  mutation DeleteDevice($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      statusMessage
    }
  }
`;

/* External dependencies */
import { filter, Observable, switchMap } from 'rxjs';

/* Local dependencies */
import { setAuthenticatedClient } from '../../../../client/graphql';
import {
  GetDevicesActions,
  GetDiviceActionTypes,
  GetDevicesRequestAction,
  getDevicesSuccess,
  getDevicesError,
} from './action';
import { getDeviceQuery } from '../query';

export function getDeviceEpic(action$): Observable<GetDevicesActions> {
  return action$.pipe(
    filter((action: GetDevicesActions) => {
      return action.type === GetDiviceActionTypes.GET_DEVICES_REQUEST;
    }),
    switchMap(({ id }: GetDevicesRequestAction) => getDevice(id).then(getDevicesSuccess).catch(getDevicesError)),
  );
}

export async function getDevice(input) {
  const graphQLClient = await setAuthenticatedClient();
  const {
    data: { getDevice },
  } = await graphQLClient.query({
    query: getDeviceQuery,
    variables: { input: { id: input } },
  });

  return getDevice;
}

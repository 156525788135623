import update from 'immutability-helper';
import { DeleteUserActionTypes, DeleteUserActions } from './action';

export interface DeleteUserState {
  loading: boolean;
  error: Error | null;
}

export const initialDeleteUserState: DeleteUserState = {
  loading: false,
  error: null,
};

export default function deleteUserReducer(state = initialDeleteUserState, action: DeleteUserActions) {
  switch (action.type) {
    case DeleteUserActionTypes.DELETE_USER_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case DeleteUserActionTypes.DELETE_USER_SUCCESS:
      return update(state, {
        loading: { $set: true },
      });
    case DeleteUserActionTypes.DELETE_USER_ERROR:
      return update(state, {
        loading: { $set: true },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

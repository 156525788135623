enum Page {
  InitialStage = 'InitialStage',
  
  RegisterEmail = 'RegisterEmail',
  Registerpassword = 'Registerpassword',
  ConfirmPassword = 'ConfirmPassword',
  RegisterCode = 'RegisterCode',
  RegisterEnd = 'RegisterEnd',

  SingInEmailPassword = 'SingInEmailPassword',
  SinginEnd = 'SinginEnd',

  NewPasswordEmail = 'NewPasswordEmail',
  NewpasswordCode = 'NewpasswordCode',
  NewpasswordPass = 'NewpasswordPass',
  NewpasswordConfirmPass = 'NewpasswordConfirmPass',
  NewpasswordEnd = 'NewpasswordEnd',

  Homescreen = 'Homescreen',
}

export default Page;

export enum NewPasswordActionType {
  NEWPASSWORD_EMAIL_UPDATE_FIELD = 'NEWPASSWORD_EMAIL_UPDATE_FIELD',
  NEWPASSWORD_PASSWORD_UPDATE_FIELD = 'NEWPASSWORD_PASSWORD_UPDATE_FIELD',
  NEW_PASSWORD_REPEAT_PASSWORD = 'NEW_PASSWORD_REPEAT_PASSWORD',
  NEWPASSWORD_PASSWORD_CODE = 'NEWPASSWORD_PASSWORD_CODE',

  NEWPASSWORD_EMAIL_EPIC = 'NEWPASSWORD_EMAIL_EPIC',
  NEWPASSWORD_EMAIL_SUCSSES = 'NEWPASSWORD_EMAIL_SUCSSES',
  NEWPASSWORD_EMAIL_ERROR = 'NEWPASSWORD_EMAIL_ERROR',

  NEWPASSWORD_CODE_EPIC = 'NEWPASSWORD_CODE_EPIC',
  NEWPASSWORD_CODE_SUCSSES = 'NEWPASSWORD_CODE_SUCSSES',
  NEWPASSWORD_CODE_ERROR = 'NEWPASSWORD_CODE_ERROR',
}


export interface NewPasswordEmailAndPassword {
  type: NewPasswordActionType.NEWPASSWORD_EMAIL_EPIC;
  username: string;
}

export interface NewPasswordEmailSucsses {
  type: NewPasswordActionType.NEWPASSWORD_EMAIL_SUCSSES;
  user: any;
}

export interface NewPasswordEmailError {
  type: NewPasswordActionType.NEWPASSWORD_EMAIL_ERROR;
  error: Error;
}



export interface NewPasswordCodeEpic {
  type: NewPasswordActionType.NEWPASSWORD_CODE_EPIC;
  username: string;
  code: string;
  password:string;
}

interface NewPasswordCodeSucsses {
  type: NewPasswordActionType.NEWPASSWORD_CODE_SUCSSES;
  user: any;
}

interface NewPasswordCodeError {
  type: NewPasswordActionType.NEWPASSWORD_CODE_ERROR;
  error: Error;
}

export interface NewPasswordEmailUpdateField {
  type: NewPasswordActionType.NEWPASSWORD_EMAIL_UPDATE_FIELD;
  email: string;
}

export interface NewPasswordPasswordUpdateField {
  type: NewPasswordActionType.NEWPASSWORD_PASSWORD_UPDATE_FIELD;
  password: string;
}

export interface NewPasswordRepeatPassword {
  type: NewPasswordActionType.NEW_PASSWORD_REPEAT_PASSWORD;
  repeatPassword: string;
}

export interface NewPasswordCode {
  type: NewPasswordActionType.NEWPASSWORD_PASSWORD_CODE;
  code: number;
}

export type NewPasswordActions =
  | NewPasswordEmailUpdateField
  | NewPasswordPasswordUpdateField
  | NewPasswordCode
  | NewPasswordEmailSucsses
  | NewPasswordEmailError
  | NewPasswordEmailAndPassword
  | NewPasswordRepeatPassword
  | NewPasswordCodeEpic
  | NewPasswordCodeSucsses
  | NewPasswordCodeError;

export function newpasswordEmailAndPassword(username: string): NewPasswordEmailAndPassword {
  return {
    type: NewPasswordActionType.NEWPASSWORD_EMAIL_EPIC,
    username,
  };
}

export function newPasswordEmailSucsses(user: any): NewPasswordEmailSucsses {
  return {
    type: NewPasswordActionType.NEWPASSWORD_EMAIL_SUCSSES,
    user,
  };
}

export function newPasswordEmailError(error: Error): NewPasswordEmailError {
  return {
    type: NewPasswordActionType.NEWPASSWORD_EMAIL_ERROR,
    error,
  };
}



export function newpasswordEmailCode(username: string, code: string,password:string): NewPasswordCodeEpic {
  return {
    type: NewPasswordActionType.NEWPASSWORD_CODE_EPIC,
    username,
    code,
    password,
  };
}

export function newPasswordCodeSucsses(user: any): NewPasswordCodeSucsses {
  return {
    type: NewPasswordActionType.NEWPASSWORD_CODE_SUCSSES,
    user,
  };
}

export function newPasswordCodeError(error: Error): NewPasswordCodeError {
  return {
    type: NewPasswordActionType.NEWPASSWORD_CODE_ERROR,
    error,
  };
}



export function newpasswordEmailUpdateField(email: string): NewPasswordEmailUpdateField {
  return {
    type: NewPasswordActionType.NEWPASSWORD_EMAIL_UPDATE_FIELD,
    email,
  };
}

export function newpasswordCode(code: number): NewPasswordCode {
  return {
    type: NewPasswordActionType.NEWPASSWORD_PASSWORD_CODE,
    code,
  };
}


export function newPasswordPasswordUpdateField(password: string): NewPasswordPasswordUpdateField {
  return {
    type: NewPasswordActionType.NEWPASSWORD_PASSWORD_UPDATE_FIELD,
    password,
  };
}

export function newPasswordRepeatPassword(repeatPassword: string): NewPasswordRepeatPassword {
  return {
    type: NewPasswordActionType.NEW_PASSWORD_REPEAT_PASSWORD,
    repeatPassword,
  };
}

import CognitoClient from '@mancho.devs/cognito';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import {
  NewPasswordActionType,
  NewPasswordActions,
  NewPasswordCodeEpic,
  NewPasswordEmailAndPassword,
  newPasswordCodeError,
  newPasswordCodeSucsses,
  newPasswordEmailError,
  newPasswordEmailSucsses,
} from './action';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function newPasswordEmailEpic(action$): Observable<NewPasswordActions> {
  return action$.pipe(
    filter((action: NewPasswordActions) => action.type === NewPasswordActionType.NEWPASSWORD_EMAIL_EPIC),
    switchMap(({ username }: NewPasswordEmailAndPassword) =>
      cognitoClient.forgotPassword(username).then(newPasswordEmailSucsses).catch(newPasswordEmailError),
    ),
  );
}

export function newPasswordEpic(action$): Observable<NewPasswordActions> {
  return action$.pipe(
    filter((action: NewPasswordActions) => action.type === NewPasswordActionType.NEWPASSWORD_CODE_EPIC),
    switchMap(({ username, code, password }: NewPasswordCodeEpic) =>
      cognitoClient.confirmPassword(username, code, password).then(newPasswordCodeSucsses).catch(newPasswordCodeError),
    ),
  );
}

export enum MapLocationActionTypes {
  SET_CORDINATS = 'SET_CORDINATS',
  SET_ADDRESS = 'SET_ADDRESS',

  SET_ADDRESS_RESET = 'SET_ADDRESS_RESET',

  SET_USER_LOCATION = 'SET_USER_LOCATION',

  SET_ACTIVE_MARKER = 'SET_ACTIVE_MARKER',
}

export interface Cordinats {
  type: MapLocationActionTypes.SET_CORDINATS;
  lat: number;
  lon: number;
}

export interface Place {
  type: MapLocationActionTypes.SET_ADDRESS;
  mapAddress: string;
}

export interface UserLocation {
  type: MapLocationActionTypes.SET_USER_LOCATION;
  lat: number;
  lon: number;
}

export interface ResetAddress {
  type: MapLocationActionTypes.SET_ADDRESS_RESET;
}

export interface SetActiveMarker {
  type: MapLocationActionTypes.SET_ACTIVE_MARKER;
  activeMarker: boolean;
  activeId: string;
}

export type MapLocationActions = Cordinats | Place | ResetAddress | UserLocation | SetActiveMarker;

export function setCoordinates(lat: number, lon: number): Cordinats {
  return {
    type: MapLocationActionTypes.SET_CORDINATS,
    lat,
    lon,
  };
}

export function setAddress(mapAddress: string): Place {
  return {
    type: MapLocationActionTypes.SET_ADDRESS,
    mapAddress,
  };
}

export function resetAddress(): ResetAddress {
  return {
    type: MapLocationActionTypes.SET_ADDRESS_RESET,
  };
}

export function userPosition(lat: number, lon: number): UserLocation {
  return {
    type: MapLocationActionTypes.SET_USER_LOCATION,
    lat,
    lon,
  };
}

export function setActiveMarker(activeMarker: boolean, activeId: string): SetActiveMarker {
  return {
    type: MapLocationActionTypes.SET_ACTIVE_MARKER,
    activeMarker,
    activeId,
  };
}

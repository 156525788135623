/* External dependencies */
import { Observable } from 'redux';
import { debounceTime, filter, switchMap } from 'rxjs';

/* Local dependencies */
import { setAuthenticatedClient } from '../../../../client/graphql';
import {
  SearchDeviceActionTypes,
  SearchDeviceActions,
  SearchDeviceRequestAction,
  searchDeviceError,
  searchDeviceSuccess,
} from './action';
import { listDevicesQuery } from '../../listDevice/query';

export function searchDeviceEpic(action$): Observable<SearchDeviceActions> {
  return action$.pipe(
    filter((action: SearchDeviceActions) => action.type === SearchDeviceActionTypes.SEARCH_DEVICES_REQUEST),
    debounceTime(1000),
    switchMap(({ searchQuery }: SearchDeviceRequestAction) =>
      searchDevices(searchQuery).then(searchDeviceSuccess).catch(searchDeviceError),
    ),
  );
}

export async function searchDevices(query: string): Promise<any> {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: {
      listDevices: { devices },
    },
  } = await graphQLClient.query({
    query: listDevicesQuery,
    variables: {
      input: {
        query,
      },
    },
  });

  return devices;
}

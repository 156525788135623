import Page from '../../pages';

export enum WebHeroActionTypes {
  GO_TO_PREVIOUS_PAGE = 'GO_TO_PREVIOUS_PAGE',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
}

export interface GoToPreviousPage {
  type: WebHeroActionTypes.GO_TO_PREVIOUS_PAGE;
  stage: Page;
}

export interface SetCurrentPage {
  type: WebHeroActionTypes.SET_CURRENT_PAGE;
  stage: Page;
}

export function goToPreviousPage(stage: Page): GoToPreviousPage {
  return {
    type: WebHeroActionTypes.GO_TO_PREVIOUS_PAGE,
    stage,
  };
}

export function setCurrentPage(stage: Page): SetCurrentPage {
  return {
    type: WebHeroActionTypes.SET_CURRENT_PAGE,
    stage,
  };
}

export type WebHeroActions = GoToPreviousPage | SetCurrentPage;

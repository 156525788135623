import { Observable } from 'redux';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { updateDeviceMutation } from '../query';
import {
  UpdateDeviceActionType,
  UpdateDeviceActions,
  UpdateDeviceRequest,
  updateDeviceError,
  updateDeviceSuccess,
} from './action';
import { filter, switchMap } from 'rxjs';

export function updateDeviceEpic(action$): Observable<UpdateDeviceActions> {
  return action$.pipe(
    filter((action: UpdateDeviceActions) => action.type === UpdateDeviceActionType.UPDATE_DEVICE_REQUEST),
    switchMap(({ updatedDeviceFields }: UpdateDeviceRequest) =>
      updateDevice(updatedDeviceFields).then(updateDeviceSuccess).catch(updateDeviceError),
    ),
  );
}

export async function updateDevice(updatedDeviceFields: any) {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: { updatedDeviceFields: device },
  } = await graphQLClient.mutate({
    mutation: updateDeviceMutation,
    variables: { input: updatedDeviceFields },
  });

  return device;
}

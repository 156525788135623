import update from 'immutability-helper';
import { NewPasswordActions, NewPasswordActionType } from './action';

export interface NewPasswordState {
  email?: string;
  password?: string;
  repeatPassword?: string;
  code?: string;
  loading?: boolean;
  error?: Error | null;
  emailError?: Error | null;
  passwordError?: Error | null;
  repeatPasswordError?: Error | null;
  username?: string;
  userPassword?: string;
  newPasswordCodeError: Error | null;
  newPasswordEmailSucsses: boolean;
  newPasswordCodeSucsses: boolean;

  newPasswordEmail: string | null;
}

export const initialNewPasswordState: NewPasswordState = {
  email: '',
  password: '',
  repeatPassword: '',
  loading: false,
  error: null,
  emailError: null,
  passwordError: null,
  repeatPasswordError: null,
  code: '',
  newPasswordCodeError: null,
  newPasswordEmailSucsses: false,
  newPasswordCodeSucsses: false,
  newPasswordEmail: null,
};

const newpasswordReducer = (state = initialNewPasswordState, action: NewPasswordActions) => {
  switch (action.type) {
    case NewPasswordActionType.NEWPASSWORD_EMAIL_UPDATE_FIELD:
      return update(state, {
        email: { $set: action.email },
      });
    case NewPasswordActionType.NEWPASSWORD_PASSWORD_UPDATE_FIELD:
      return update(state, {
        password: { $set: action.password },
      });
    case NewPasswordActionType.NEW_PASSWORD_REPEAT_PASSWORD:
      return update(state, {
        repeatPassword: { $set: action.repeatPassword },
      });
    case NewPasswordActionType.NEWPASSWORD_PASSWORD_CODE:
      return update(state, {
        code: { $set: action.code },
      });

    case NewPasswordActionType.NEWPASSWORD_EMAIL_EPIC:
      return update(state, {
        username: { $set: action.username },
      });
    case NewPasswordActionType.NEWPASSWORD_EMAIL_SUCSSES:
      return update(state, {
        newPasswordEmailSucsses: { $set: true },
        newPasswordEmail: { $set: state.email },
      });
    case NewPasswordActionType.NEWPASSWORD_EMAIL_ERROR:
      return update(state, {
        error: { $set: action.error },
      });

    case NewPasswordActionType.NEWPASSWORD_CODE_EPIC:
      return update(state, {
        username: { $set: action.username },
        code: { $set: action.code },
        password: { $set: action.password },
      });

    case NewPasswordActionType.NEWPASSWORD_CODE_SUCSSES:
      return update(state, {
        newPasswordCodeSucsses: { $set: true },
      });
    case NewPasswordActionType.NEWPASSWORD_CODE_ERROR:
      return update(state, {
        newPasswordCodeError: { $set: action.error },
      });

    default:
      return state;
  }
};

export default newpasswordReducer;

import update from 'immutability-helper';
import { Device } from '../../types';
import { ListDevicesAction, ListDevicesActionTypes } from './action';

export interface ListDevicesState {
  loading: boolean;
  error: null | Error;
  devices: Device[];
  total: number;
  listDevicesSuccess: boolean;
}

export const initialListDeviceStatus: ListDevicesState = {
  loading: false,
  error: null,
  devices: [],
  total: 0,
  listDevicesSuccess: false,
};

export default function listDeviceReducer(state = initialListDeviceStatus, action: ListDevicesAction) {
  switch (action.type) {
    case ListDevicesActionTypes.LIST_DEVICE_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case ListDevicesActionTypes.LIST_DEVICE_SUCCESS:
      if (action.filter && action.filter.devices) {
        return update(state, {
          loading: { $set: false },
          devices: { $set: action.filter.devices },
          total: { $set: action.filter.total },
          listDevicesSuccess: { $set: true },
        });
      } else {
        return state;
      }
    case ListDevicesActionTypes.LIST_DEVICE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

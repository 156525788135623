import { Observable, filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { listUsersQuery } from '../query';
import { ListUserActionTypes, ListUserActions, ListUserRequest, listUserError, listUserSuccess } from './action';

export function listUserEpic(action$): Observable<ListUserActions> {
  return action$.pipe(
    filter((action: ListUserActions) => action.type === ListUserActionTypes.LIST_USER_REQUEST),
    switchMap(({ userFilter }: ListUserRequest) => listUsers(userFilter).then(listUserSuccess).catch(listUserError)),
  );
}

export async function listUsers({ filter }: any): Promise<any> {
  const graphQLClient = await setAuthenticatedClient();

  const {
    data: {
      listUsers: { users, total },
    },
  } = await graphQLClient.query({
    query: listUsersQuery,
    variables: {
      input: {
        filter,
      },
    },
  });

  return { total, users };
}

export enum WebHeaderActiontype {
  WEB_HEADER_INPUT = 'WEB_HEADER_INPUT',
  SET_OPEN_LIDERBOARD = 'SET_OPEN_LIDERBOARD',
  SET_CLOSE_LIDERBOARD = 'SET_CLOSE_LIDERBOARD',
  SET_SHOW_PROFILE = 'SET_SHOW_PROFILE',
  SET_HIDE_PROFILE = 'SET_HIDE_PROFILE',
  SET_LOGOUT = 'SET_LOGOUT',
  CANCELING_LOGOUT = 'CANCELING_LOGOUT',
}

export interface WebHeaderInput {
  type: WebHeaderActiontype.WEB_HEADER_INPUT;
  text: string;
}

export interface OpenLiderboard {
  type: WebHeaderActiontype.SET_OPEN_LIDERBOARD;
}

export interface CloseLiderboard {
  type: WebHeaderActiontype.SET_CLOSE_LIDERBOARD;
}

export interface ShowProfile {
  type: WebHeaderActiontype.SET_SHOW_PROFILE;
}

export interface hideProfile {
  type: WebHeaderActiontype.SET_HIDE_PROFILE;
}

export interface SetLogOut {
  type: WebHeaderActiontype.SET_LOGOUT;
}

export interface CancelingLogOut {
  type: WebHeaderActiontype.CANCELING_LOGOUT;
}

export function webHeaderInput(text: string) {
  return {
    type: WebHeaderActiontype.WEB_HEADER_INPUT,
    text,
  };
}

export function setOpenLiderbord(): OpenLiderboard {
  return {
    type: WebHeaderActiontype.SET_OPEN_LIDERBOARD,
  };
}
export function setClodeLiderbord(): CloseLiderboard {
  return {
    type: WebHeaderActiontype.SET_CLOSE_LIDERBOARD,
  };
}

export function setShowProfile(): ShowProfile {
  return {
    type: WebHeaderActiontype.SET_SHOW_PROFILE,
  };
}
export function setHideProfile() {
  return {
    type: WebHeaderActiontype.SET_HIDE_PROFILE,
  };
}

export function setLogOut() {
  return {
    type: WebHeaderActiontype.SET_LOGOUT,
  };
}
export function cancelingLogOut() {
  return {
    type: WebHeaderActiontype.CANCELING_LOGOUT,
  };
}

export type WebHeaderAction =
  | WebHeaderInput
  | OpenLiderboard
  | CloseLiderboard
  | ShowProfile
  | hideProfile
  | CancelingLogOut
  | SetLogOut;

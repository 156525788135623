import { Observable } from 'redux';
import { filter, switchMap } from 'rxjs';
import { setAuthenticatedClient } from '../../../../client/graphql';
import { deleteUserMutation } from '../query';
import {
  DeleteUserActionTypes,
  DeleteUserActions,
  DeleteUserRequest,
  deleteUserError,
  deleteUserSuccess,
} from './action';

export function deleteUserEpic(action$): Observable<DeleteUserActions> {
  return action$.pipe(
    filter((action: DeleteUserActions) => action.type === DeleteUserActionTypes.DELETE_USER_REQUEST),
    switchMap((action: DeleteUserRequest) => {
      return deleteUser(action.id)
        .then(deleteUserSuccess)
        .catch((error) => {
          return deleteUserError(error);
        });
    }),
  );
}

export async function deleteUser(id) {
  try {
    const graphQLClient = await setAuthenticatedClient();
    console.log('GraphQL client set up');

    const response = await graphQLClient.mutate({
      mutation: deleteUserMutation,
      variables: { input: { id } },
    });

    console.log('GraphQL mutation response:', response);

    const user = response.data.deleteUser;
    return user;
  } catch (error) {
    console.error('Error in deleteDevice function:', error);
    throw error;
  }
}

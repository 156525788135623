import { GetUserActionType, GetuserActions, getUserSuccess } from './action';
import update from 'immutability-helper';

export enum UserType {
  USER = 'USER',
}

export interface GetUserState {
  loading: boolean;
  error: null | Error;
  getUserData: {
    avatar: string;
    email: string;
    groupName: string;
    id: string;
    joinDate: number;
    phoneNumber: string;
    point: number;
    status: string;
    username: string;
    __typename: UserType;
  };
  getUserSuccess: boolean;
}

export const initialGetUserStatus: GetUserState = {
  loading: false,
  error: null,
  getUserData: null,
  getUserSuccess: false,
};

export default function getUserReducer(state = initialGetUserStatus, action: GetuserActions) {
  switch (action.type) {
    case GetUserActionType.GET_USER_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case GetUserActionType.GET_USER_SUCCESS:
      return update(state, {
        getUserData: { $set: action.getUserData },
        getUserSuccess: { $set: true },
        loading: { $set: false },
      });
    case GetUserActionType.GET_USER_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

import update from 'immutability-helper';
import { Device } from '../../types';
import { SearchDeviceActionTypes, SearchDeviceActions } from './action';

export interface SearchDeviceState {
  loadingSearch: boolean;
  error: null | Error;
  searchDevices: Device[];
  selectedDevice: Device | null;
}

export const initialListDeviceState: SearchDeviceState = {
  loadingSearch: false,
  error: null,
  searchDevices: [],
  selectedDevice: null,
};

export default function searchDeviceReducer(state = initialListDeviceState, action: SearchDeviceActions) {
  switch (action.type) {
    case SearchDeviceActionTypes.SEARCH_DEVICES_REQUEST:
      return update(state, {
        loadingSearch: { $set: true },
      });
    case SearchDeviceActionTypes.SEARCH_DEVICES_SUCCESS:
      return update(state, {
        loadingSearch: { $set: false },
        searchDevices: { $set: action.searchDevices },
      });
    case SearchDeviceActionTypes.SEARCH_DEVICES_ERROR:
      return update(state, {
        loadingSearch: { $set: false },
        error: { $set: action.error },
      });
    case SearchDeviceActionTypes.ZOOM_TO_DEVICE:
      return update(state, {
        selectedDevice: { $set: action.payload },
      });
    default:
      return state;
  }
}

export enum InitialClientActionsType {
  INIT_CLIENT_REQUEST = 'INIT_CLIENT_REQUEST',
  INIT_CLIENT_SUCCESS = 'INIT_CLIENT_SUCCESS',
  INIT_CLIENT_ERROR = 'INIT_CLIENT_ERROR',
}

export interface InitialClientRequest {
  type: InitialClientActionsType.INIT_CLIENT_REQUEST;
}

export interface InitialClientSuccess {
  type: InitialClientActionsType.INIT_CLIENT_SUCCESS;
  session: any;
}

export interface InitialClientError {
  type: InitialClientActionsType.INIT_CLIENT_ERROR;
  error: Error;
}

export type InitialAction = InitialClientRequest | InitialClientSuccess | InitialClientError;

export function initialClientRequest(): InitialClientRequest {
  return {
    type: InitialClientActionsType.INIT_CLIENT_REQUEST,
  };
}

export function initialClientSuccess(session: any): InitialClientSuccess {
  return {
    type: InitialClientActionsType.INIT_CLIENT_SUCCESS,
    session,
  };
}

export function initialClientError(error: Error): InitialClientError {
  return {
    type: InitialClientActionsType.INIT_CLIENT_ERROR,
    error,
  };
}

import update from 'immutability-helper';
import { DeleteDeviceActionType, DeleteDeviceActions } from './action';

export interface DeleteDeviceState {
  loading: boolean;
  deleteError: null | Error;
  deleteNotification: boolean;
  deleteDevice: any;
}

export const initialDeleteDeviceState: DeleteDeviceState = {
  loading: false,
  deleteError: null,
  deleteNotification: false,
  deleteDevice: null,
};

export default function deleteDeviceReducer(state = initialDeleteDeviceState, action: DeleteDeviceActions) {
  switch (action.type) {
    case DeleteDeviceActionType.DELETE_DEVICE_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case DeleteDeviceActionType.DELETE_DEVICE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        deleteDevice: { $set: action.deleteDevice },
      });
    case DeleteDeviceActionType.DELETE_DEVICE_ERROR:
      return update(state, {
        loading: { $set: false },
        deleteError: { $set: action.deleteError },
      });
    case DeleteDeviceActionType.OPEN_DELETE_DEVICE_NOTIFICATION:
      return update(state, {
        deleteNotification: { $set: true },
      });
    case DeleteDeviceActionType.CLOSE_DELETE_DEVICE_NOTIFICATION:
      return update(state, {
        deleteNotification: { $set: false },
      });
    default:
      return state;
  }
}

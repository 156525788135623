import update from 'immutability-helper';
import { CreateDeviceAction, CreateDeviceActionTypes } from './action';

enum DeviceStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

export interface CreateDeviceState {
  loading: boolean;
  createError: null | Error;
  notes: string;
  organization: string;
  createDeviceData: {
    address: string;
    approvalsCount: number;
    id: string;
    location: {
      lat: number;
      lon: number;
    };
    organization: string;
    notes: string;
    registrationDate: string;
    status: DeviceStatus;
    user: any;
  } | null;
  createDeviceNotification: boolean;
}

export const initialCreateDeviceStatus: CreateDeviceState = {
  loading: false,
  createError: null,
  notes: '',
  organization: '',
  createDeviceData: null,
  createDeviceNotification: false,
};

export default function createDeviceReducer(state = initialCreateDeviceStatus, action: CreateDeviceAction) {
  switch (action.type) {
    case CreateDeviceActionTypes.CREATE_DEVICES_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case CreateDeviceActionTypes.CREATE_DEVICES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        createDeviceData: { $set: action.createDeviceData },
      });
    case CreateDeviceActionTypes.CREATE_DEVICES_ERROR:
      return update(state, {
        loading: { $set: false },
        createError: { $set: action.createError },
      });
    case CreateDeviceActionTypes.NOTES:
      return update(state, {
        notes: { $set: action.notes },
      });
    case CreateDeviceActionTypes.DEVICE_NAME:
      return update(state, {
        organization: { $set: action.organization },
      });
    case CreateDeviceActionTypes.OPEN_CREATE_DEVICE_NOTIFICATION:
      return update(state, {
        createDeviceNotification: { $set: true },
      });
    case CreateDeviceActionTypes.CLOSE_CREATE_DEVICE_NOTIFICATION:
      return update(state, {
        createDeviceNotification: { $set: false },
      });
    case CreateDeviceActionTypes.SET_DEFAULT_STATE:
      return initialCreateDeviceStatus;
    default:
      return state;
  }
}

import update from 'immutability-helper';
import { InitialClientActionsType, InitialAction } from './action';

export interface InitialClientState {
  initialClientLoading: boolean;
  user: boolean;
  session?: any;
  id: string;
  email: string;
  initialError: Error;
  initSuccess: boolean;
}

export const initialClientState: InitialClientState = {
  initialClientLoading: false,
  user: false,
  id: '',
  email: '',
  initialError: null,
  initSuccess: false,
};

export default function initialClientReducer(state = initialClientState, action: InitialAction) {
  switch (action.type) {
    case InitialClientActionsType.INIT_CLIENT_REQUEST:
      return update(state, {
        initialClientLoading: { $set: true },
        user: { $set: false },
      });
    case InitialClientActionsType.INIT_CLIENT_SUCCESS:
      const emailAttribute = action.session.find((attr) => attr.Name === 'email');
      const emailValue = emailAttribute ? emailAttribute.Value : null;
      const valueAttribute = action.session.find((attr) => attr.Name === 'userId');
      const value = valueAttribute ? valueAttribute.Value : null;

      return update(state, {
        session: { $set: action.session },
        email: { $set: emailValue },
        user: { $set: true },
        initialClientLoading: { $set: false },
        id: { $set: value },
        initSuccess: { $set: true },
      });
    case InitialClientActionsType.INIT_CLIENT_ERROR:
      return update(state, {
        user: { $set: false },
        initialClientLoading: { $set: false },
        initialError: { $set: action.error },
      });
    default:
      return state;
  }
}

/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import registerReducer from '../components/registerweb/redux/reducer';
import newpasswordReducer from '../components/newpassword/redux/reducer';
import singinReducer from '../components/singinweb/redux/reducer';
import webHeroReducer from '../components/webhero/redux/reducer';
import { registerEpic, smsCodeEpic } from '../components/registerweb/redux/epic';
import { singInEmailEpic } from '../components/singinweb/redux/epic';
import { newPasswordEmailEpic, newPasswordEpic } from '../components/newpassword/redux/epic';
import mapLocationReducer from '../map/redux/reducer';
import addComponentsReducer from '../components/add_components/redux/reducer';
import initialClientReducer from '../components/WebApp/redux/reducer';
import { initialClientEpic } from '../components/WebApp/redux/epic';
import { createDeviceEpic } from '../feature/devices/createDevice/redux/epic';
import { getDeviceEpic } from '../feature/devices/getDevices/redux/epic';
import { listDeviceEpic } from '../feature/devices/listDevice/redux/epic';
import { updateDeviceEpic } from '../feature/devices/updateDevice/redux/epic';
import { getUserEpic } from '../feature/users/getUsers/redux/epic';
import { deleteDeviceEpic } from '../feature/devices/deleteDevice/redux/epic';
import { updateUserEpic } from '../feature/users/updateUser/redux/epic';
import { listUserEpic } from '../feature/users/listUsers/redux/epic';
import { deleteUserEpic } from '../feature/users/deleteUser/redux/epic';
import createDeviceReducer from '../feature/devices/createDevice/redux/reducer';
import getDevicesReducer from '../feature/devices/getDevices/redux/reducer';
import listDeviceReducer from '../feature/devices/listDevice/redux/reducer';
import updateDeviceReducer from '../feature/devices/updateDevice/redux/reducer';
import deleteDeviceReducer from '../feature/devices/deleteDevice/redux/reducer';
import getUserReducer from '../feature/users/getUsers/redux/reducer';
import listUserReducer from '../feature/users/listUsers/redux/reducer';
import deleteUserReducer from '../feature/users/deleteUser/redux/reducer';
import updateUserReducer from '../feature/users/updateUser/redux/reducer';
import { approveDeviceEpic } from '../feature/devices/approveDevice/redux/epic';
import searchDeviceReducer from '../feature/devices/searchDevice/redux/reducer';
import { searchDeviceEpic } from '../feature/devices/searchDevice/redux/epic';
import approvedDeviceReducer from '../feature/devices/approveDevice/redux/reducer';
import webheaderReducer from '../components/webheader/redux/reducer';

const rootEpic = combineEpics(
  approveDeviceEpic,
  createDeviceEpic,
  deleteDeviceEpic,
  deleteUserEpic,
  getDeviceEpic,
  getUserEpic,
  initialClientEpic,
  listDeviceEpic,
  listUserEpic,
  newPasswordEmailEpic,
  newPasswordEpic,
  registerEpic,
  searchDeviceEpic,
  singInEmailEpic,
  smsCodeEpic,
  updateDeviceEpic,
  updateUserEpic,
);

const rootReducer = combineReducers({
  addComponentsReducer,
  approvedDeviceReducer,
  createDeviceReducer,
  deleteDeviceReducer,
  deleteUserReducer,
  getDevicesReducer,
  getUserReducer,
  initialClientReducer,
  listDeviceReducer,
  listUserReducer,
  mapLocationReducer,
  newpasswordReducer,
  registerReducer,
  searchDeviceReducer,
  singinReducer,
  updateDeviceReducer,
  updateUserReducer,
  webheaderReducer,
  webHeroReducer,
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;

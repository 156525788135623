export enum UpdateUserActionTypes {
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',

  OPEN_UPDATE_USER_NOTIFICATION = 'OPEN_UPDATE_USER_NOTIFICATION',
  CLOSE_UPDATE_USER_NOTIFICATION = 'CLOSE_UPDATE_USER_NOTIFICATION',
}

export interface UpdateUserRequest {
  type: UpdateUserActionTypes.UPDATE_USER_REQUEST;
  updatedUserFields: any;
}

export interface UpdateUserSuccess {
  type: UpdateUserActionTypes.UPDATE_USER_SUCCESS;
  updateUserSuccessFields: any;
}

export interface UpdateUserError {
  type: UpdateUserActionTypes.UPDATE_USER_ERROR;
  updateUserError: Error;
}

export interface OpenUpdateUserNotification {
  type: UpdateUserActionTypes.OPEN_UPDATE_USER_NOTIFICATION;
}
export interface CloseUpdateUserNotification {
  type: UpdateUserActionTypes.CLOSE_UPDATE_USER_NOTIFICATION;
}

export type UpdateUserActions =
  | UpdateUserRequest
  | UpdateUserSuccess
  | UpdateUserError
  | OpenUpdateUserNotification
  | CloseUpdateUserNotification;

export function updateUserRequest(updatedUserFields: any): UpdateUserRequest {
  return {
    type: UpdateUserActionTypes.UPDATE_USER_REQUEST,
    updatedUserFields,
  };
}
export function updateUserSuccess(updateUserSuccessFields: any): UpdateUserSuccess {
  return {
    type: UpdateUserActionTypes.UPDATE_USER_SUCCESS,
    updateUserSuccessFields,
  };
}

export function updateUserError(updateUserError: Error): UpdateUserError {
  return {
    type: UpdateUserActionTypes.UPDATE_USER_ERROR,
    updateUserError,
  };
}

export function openUpdateUserNotification(): OpenUpdateUserNotification {
  return {
    type: UpdateUserActionTypes.OPEN_UPDATE_USER_NOTIFICATION,
  };
}

export function closeUpdateUserNotification(): CloseUpdateUserNotification {
  return {
    type: UpdateUserActionTypes.CLOSE_UPDATE_USER_NOTIFICATION,
  };
}

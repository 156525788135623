export enum DeleteUserActionTypes {
  DELETE_USER_REQUEST = 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR = 'DELETE_USER_ERROR',
}

export interface DeleteUserRequest {
  type: DeleteUserActionTypes.DELETE_USER_REQUEST;
  id: string;
}

export interface DeleteUserSuccess {
  type: DeleteUserActionTypes.DELETE_USER_SUCCESS;
}

export interface DeleteUserError {
  type: DeleteUserActionTypes.DELETE_USER_ERROR;
  error: Error;
}

export type DeleteUserActions = DeleteUserRequest | DeleteUserSuccess | DeleteUserError;

export function deleteUserRequest(id: string): DeleteUserRequest {
  return {
    type: DeleteUserActionTypes.DELETE_USER_REQUEST,
    id,
  };
}

export function deleteUserSuccess(): DeleteUserSuccess {
  return {
    type: DeleteUserActionTypes.DELETE_USER_SUCCESS,
  };
}

export function deleteUserError(error: Error): DeleteUserError {
  return {
    type: DeleteUserActionTypes.DELETE_USER_ERROR,
    error,
  };
}

export enum CreateDeviceActionTypes {
  CREATE_DEVICES_REQUEST = 'CREATE_DEVICES_REQUEST',
  CREATE_DEVICES_SUCCESS = 'CREATE_DEVICES_SUCCESS',
  CREATE_DEVICES_ERROR = 'CREATE_DEVICES_ERROR',
  NOTES = 'NOTES',
  DEVICE_NAME = 'DEVICE_NAME',
  SET_DEFAULT_STATE = 'SET_DEFAULT_STATE',

  OPEN_CREATE_DEVICE_NOTIFICATION = 'OPEN_CREATE_DEVICE_NOTIFICATION',
  CLOSE_CREATE_DEVICE_NOTIFICATION = 'CLOSE_CREATE_DEVICE_NOTIFICATION',
}

export interface SetDefaultState {
  type: CreateDeviceActionTypes.SET_DEFAULT_STATE;
}

export interface CreateDeviceRequestAction {
  type: CreateDeviceActionTypes.CREATE_DEVICES_REQUEST;
  input: any;
}

export interface CreateDeviceSuccessAction {
  type: CreateDeviceActionTypes.CREATE_DEVICES_SUCCESS;
  createDeviceData: any;
}

export interface CreateDeviceErrorAction {
  type: CreateDeviceActionTypes.CREATE_DEVICES_ERROR;
  createError: Error;
}
export interface NotesAction {
  type: CreateDeviceActionTypes.NOTES;
  notes: string;
}
export interface DeviceNameAction {
  type: CreateDeviceActionTypes.DEVICE_NAME;
  organization: string;
}

export interface OpenCreateDeviceNotification {
  type: CreateDeviceActionTypes.OPEN_CREATE_DEVICE_NOTIFICATION;
}
export interface CloseCreateDeviceNotification {
  type: CreateDeviceActionTypes.CLOSE_CREATE_DEVICE_NOTIFICATION;
}

export type CreateDeviceAction =
  | CreateDeviceRequestAction
  | CreateDeviceErrorAction
  | CreateDeviceSuccessAction
  | NotesAction
  | DeviceNameAction
  | SetDefaultState
  | OpenCreateDeviceNotification
  | CloseCreateDeviceNotification;

export function setDefaultState(): SetDefaultState {
  return {
    type: CreateDeviceActionTypes.SET_DEFAULT_STATE,
  };
}

export function createDeviceRequest(input: any): CreateDeviceRequestAction {
  return {
    type: CreateDeviceActionTypes.CREATE_DEVICES_REQUEST,
    input,
  };
}

export function createDeviceSuccess(createDeviceData: any): CreateDeviceSuccessAction {
  return {
    type: CreateDeviceActionTypes.CREATE_DEVICES_SUCCESS,
    createDeviceData,
  };
}

export function createDeviceError(createError: Error): CreateDeviceErrorAction {
  return {
    type: CreateDeviceActionTypes.CREATE_DEVICES_ERROR,
    createError,
  };
}

export function deviceNotes(notes: string): NotesAction {
  return {
    type: CreateDeviceActionTypes.NOTES,
    notes,
  };
}

export function deviceIdentifier(organization: string): DeviceNameAction {
  return {
    type: CreateDeviceActionTypes.DEVICE_NAME,
    organization,
  };
}

export function openCreateDeviceNotification(): OpenCreateDeviceNotification {
  return {
    type: CreateDeviceActionTypes.OPEN_CREATE_DEVICE_NOTIFICATION,
  };
}

export function closeCreateDeviceNotification(): CloseCreateDeviceNotification {
  return {
    type: CreateDeviceActionTypes.CLOSE_CREATE_DEVICE_NOTIFICATION,
  };
}

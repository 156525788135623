import update from 'immutability-helper';
import { WebHeaderAction, WebHeaderActiontype } from './action';

export interface WebHeaderState {
  text?: string;
  openLiderboard: boolean;
  showProfile: boolean;
  logOutModal: boolean;
}

export const initialWebHeaderState: WebHeaderState = {
  text: '',
  openLiderboard: false,
  showProfile: false,
  logOutModal: false,
};

const webheaderReducer = (state = initialWebHeaderState, action: WebHeaderAction) => {
  switch (action.type) {
    case WebHeaderActiontype.WEB_HEADER_INPUT:
      return update(state, {
        text: { $set: action.text },
      });
    case WebHeaderActiontype.SET_OPEN_LIDERBOARD:
      return update(state, {
        openLiderboard: { $set: true },
      });
    case WebHeaderActiontype.SET_CLOSE_LIDERBOARD:
      return update(state, {
        openLiderboard: { $set: false },
      });
    case WebHeaderActiontype.SET_SHOW_PROFILE:
      return update(state, {
        showProfile: { $set: true },
      });
    case WebHeaderActiontype.SET_HIDE_PROFILE:
      return update(state, {
        showProfile: { $set: false },
      });
    case WebHeaderActiontype.SET_LOGOUT:
      return update(state, {
        logOutModal: { $set: true },
      });
    case WebHeaderActiontype.CANCELING_LOGOUT:
      return update(state, {
        logOutModal: { $set: false },
      });
    default:
      return state;
  }
};
export default webheaderReducer;

import { gql } from '@apollo/client';

export const listDevicesQuery = gql`
  query listDevices($input: ListDevicesInput!) {
    listDevices(input: $input) {
      devices {
        typename: __typename
        ... on Terminal {
          id
          notes
          registrationDate
          status
          approvalsCount
          organization
          address
          location {
            lat
            lon
          }
          user {
            id
          }
        }
      }
      total
    }
  }
`;

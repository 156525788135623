import update from 'immutability-helper';
import { ApproveDeviceTypes, ApproveDeviceActions } from './action';

export interface ApproveDeviceState {
  approvedDevice: any;
  aproveNotification: boolean;
  approveLoading: boolean;
  approveError: Error | null;
  approveSuccess: boolean;
}

export const initialApproveDeviceState: ApproveDeviceState = {
  approvedDevice: null,
  aproveNotification: false,
  approveLoading: false,
  approveError: null,
  approveSuccess: false,
};

export default function approvedDeviceReducer(state = initialApproveDeviceState, action: ApproveDeviceActions) {
  switch (action.type) {
    case ApproveDeviceTypes.APPROVE_DEVICE_REQUEST:
      return update(state, {
        approveLoading: { $set: true },
      });
    case ApproveDeviceTypes.APPROVE_DEVICE_SUCCESS:
      return update(state, {
        approveLoading: { $set: false },
        approvedDevice: { $set: action.payload },
        approveSuccess: { $set: true },
      });
    case ApproveDeviceTypes.APPROVE_DEVICE_ERROR:
      return update(state, {
        approveLoading: { $set: false },
        approveError: { $set: action.approveError },
      });
    case ApproveDeviceTypes.OPEN_APPROVE_NOTIFICATION:
      return update(state, {
        aproveNotification: { $set: true },
      });
    case ApproveDeviceTypes.CLOSE_APPROVE_NOTIFICATION:
      return update(state, {
        aproveNotification: { $set: false },
      });
    case ApproveDeviceTypes.SET_RESET_STATE:
      return initialApproveDeviceState;
    default:
      return state;
  }
}

import update from 'immutability-helper';
import { User } from '../../types';
import { ListUserActions, ListUserActionTypes } from './action';

export interface ListUserState {
  loading: boolean;
  error: Error | null;
  users: User[];
  total: number;
}

export const initialListUserState: ListUserState = {
  loading: false,
  error: null,
  users: [],
  total: 0,
};

export default function listUserReducer(state = initialListUserState, action: ListUserActions) {
  switch (action.type) {
    case ListUserActionTypes.LIST_USER_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case ListUserActionTypes.LIST_USER_SUCCESS:
      if (action.type && action.userFilter.users) {
        return update(state, {
          loading: { $set: false },
          users: { $set: action.userFilter.users },
          total: { $set: action.userFilter.total },
        });
      }
    case ListUserActionTypes.LIST_USER_ERROR:
      return update(state, {
        loading: { $set: false },
        // error: { $set:  },
      });
    default:
      return state;
  }
}
